import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const defaultRouterMap = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login_V2'),
        hidden: true
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/404'),
        hidden: true
    }
]

export const constRouterMap = [
    {
        path: '/',
        name: '首页',
        component: () => import('@/layout/Index'),
        redirect: '/dashboard',
        meta: {title: '欢迎页',icon: 'el-icon-location'},
        children: [{
            path: 'dashboard',
            name: '欢迎页',
            component: () => import('@/views/dashboard/Index'),
        }]
    },
    {
        path: '/masterdata',
        name: '主数据',
        component: () => import('@/layout/Index'),
        redirect: '/masterdata/user',
        meta: {title: '主数据', icon: 'el-icon-user-solid'},
        children: [
            {
                path: 'user',
                name: '用户信息',
                meta: {title: '用户信息'},
                component: () => import('@/views/user/User'),
            },
            {
                path: 'company',
                name: '公司信息',
                meta: {title: '公司信息'},
                component: () => import('@/views/company/Company'),
            },
            {
                path: 'create-user',
                name: '添加用户',
                meta: {title: '添加用户'},
                hidden: true,
                component: () => import('@/views/user/AddUser'),
            },
        ]
    },
    {
        path: '/system',
        name: '权限配置',
        component: () => import('@/layout/Index'),
        redirect: '/system/resource',
        meta: {title: '权限配置', icon: 'el-icon-setting'},
        children: [
            {
                path: 'resource',
                name: '资源信息',
                meta: {title: '资源', icon: 'el-icon-s-data'},
                component: () => import('@/views/resource/Resource'),
                children: [
                    {
                        path: 'create-resource',
                        name: '创建资源',
                        hidden: true,
                        meta: {title: '创建资源'},
                        component: () => import('@/views/resource/AddResource'),
                    }
                ]
            },
            {
                path: 'role',
                name: '角色信息',
                component: () => import('@/views/role/Role'),
                meta: {title: '角色', icon: 'el-icon-s-tools'},
                children: [
                    {
                        path: 'create-role',
                        name: '创建角色信息',
                        hidden: true,
                        meta: {title: '创建角色信息'},
                        component: () => import('@/views/role/AddRole'),
                    }
                ]
            },
            {
                path: 'group',
                name: '组信息',
                component: () => import('@/views/group/Group'),
                meta: {title: '组', icon: 'el-icon-menu'},
                children: [
                    {
                        path: 'create-group',
                        name: '创建组信息',
                        hidden: true,
                        meta: {title: '创建组信息'},
                        component: () => import('@/views/group/AddGroup'),
                    }
                ]
            },
        ]
    },
    {
        path: '/invoice',
        name: '发票池',
        component: () => import('@/layout/Index'),
        redirect: '/invoice/index',
        meta: {title: '发票池', icon: 'el-icon-guide'},
        children: [{
            path: 'index',
            name: '发票信息',
            meta: {title: '发票信息'},
            component: () => import('@/views/invoice/Invoice_V1'),
        }]
    },

    {path: '*', redirect: '/404', hidden: true}
]

export default new VueRouter({
    routes: defaultRouterMap
})

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
