import {defaultRouterMap, constRouterMap} from '../../router'
import {getToken} from '@/utils/auth'
import request from "@/utils/request";

const _import = require('@/router/_import') //获取组件的方法
import Layout from '@/layout/Index'

const state = {
    menus: []
}

const getters = {
    menus: state => state.menus
}

const mutations = {
    SET_MENU: (state, menus) => {
        state.menus = defaultRouterMap.concat(menus)
    }
}

const actions = {
    generatorMenu({commit}) {
        return new Promise(resolve => {
            const token = getToken()

            return request({
                url: '/oauth/user?access_token=' + token,
                baseURL:'http://127.0.0.1:8091',
                method: 'post',
                contentType: 'application/json',
            }).then((response) => {
                const data = response.data

                filterAsyncRouter(data.sysResourceList)

                commit('SET_MENU', data.sysResourceList)
                resolve(data.sysResourceList)
            })

        })
    }
}

export default {
    namespace: true,
    state,
    mutations,
    getters,
    actions
}

function filterAsyncRouter(asyncRouterMap) { //遍历后台传来的路由字符串，转换为组件对象
    const accessedRouters = asyncRouterMap.filter(route => {

        if (route.component) {
            if (route.component === 'layout/Index') { //Layout组件特殊处理
                route.component = Layout
            } else {
                route.component = _import(route.component)
            }
        }
        if (route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children)
        }
        return true
    })

    return accessedRouters
}
