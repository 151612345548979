import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {getToken, removeToken} from '@/utils/auth'

NProgress.configure({showSpinner: false}) // NProgress Configuration

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
    NProgress.start()

    const token = getToken()

    if (token) {
        if (to.path === '/login') {
            next({path: '/'})
            NProgress.done()
        } else {
            // checkToken Invalid
            if (store.getters.menus.length > 0) {
                next();
                NProgress.done()
            } else {
                const routers = await store.dispatch('generatorMenu')
                router.addRoutes(routers)

                // next(`/login?redirect=${to.path}`)
                next({ ...to, replace: true })
                NProgress.done()
            }
        }
    }else {
        if(whiteList.indexOf(to.path) !== -1){
            next()
        }else {
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    }


})

router.afterEach(() => {
    NProgress.done()
})
