<template>
  <div style="height: calc(100% - 60px)">
    <Logo :collapse="false"/>
    <el-menu
        :default-active="activeMenu"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        background-color="#fff"
        text-color="#2b2f3a">
<!--        background-color="#2b2f3a"-->
<!--        text-color="#fff">-->
      <Menu v-for="menu in menus" :key="menu.path" :menu="menu" :base-path="menu.path"/>
    </el-menu>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Menu from "@/layout/components/side/Menu";
import Logo from "@/layout/components/side/Logo";

export default {
  name: "SideMenu",
  components: {
    Menu,
    Logo
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  computed: {
    ...mapGetters(['menus']),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  }
}
</script>

<style scoped>
.el-menu {
  height: 100%;
  overflow-x: hidden !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.el-menu::-webkit-scrollbar {
  width: 3px;
}
.scrollbar-wrapper {

}

</style>
