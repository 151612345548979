<template>
  <div v-if="!menu.hidden">
    <el-submenu :index="menu.path">
      <template slot="title">
        <i :class="menu.meta.icon"></i>
        <span>{{ menu.name }}</span>
      </template>
      <div v-for="children in menu.children" :key="resolvePath(children.path)">
        <app-link v-if="!children.hidden" :to="resolvePath(children.path)">
        <el-menu-item :index="resolvePath(children.path)">
            {{ children.name }}
        </el-menu-item>
        </app-link>
      </div>

    </el-submenu>
  </div>
</template>

<script>
import AppLink from "@/layout/components/side/Link";
import path from "path";
import { isExternal } from '@/utils/validate'

export default {
  name: "Menu",
  components: {AppLink},
  props: {
    menu: {
      type: Object,
      require: true
    },
    basePath: {
      type: String,
      require: true
    }
  },
  methods: {
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>

<style scoped>

.el-submenu .el-menu-item {
  min-width: 0 !important;
}
.el-submenu__title:hover {
  background-color: #38b3ff !important;
  color: #fff;
}
.el-menu-item:hover {
  background-color: #38b3ff !important;
  color: #fff;
}
</style>
