<template>
  <el-container>
    <el-aside width="220px">
      <SideMenu/>
    </el-aside>
    <el-container>
      <el-header>
        <Navbar/>
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
      <el-footer>© 2019-{{ new Date().getFullYear() }}
        <a style="text-decoration: none;" href="https://www.yanzhenyidai.com" target="_blank">虫子（中国）网络科技有限公司</a>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import Navbar from "@/layout/components/Navbar";
import SideMenu from "@/layout/components/SideMenu";

export default {
  name: "Index",
  components: {
    Navbar,
    SideMenu
  }
}
</script>

<style scoped>

.el-header {
  /*background-color: #B3C0D1;*/
  color: #333;
  line-height: 60px;
  margin-left: 5px;
  padding: 0 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}
.el-footer {
  /*background-color: #E7EEF3;*/
  color: #333;
  height: 20px !important;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  margin-left: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  height: 100% !important;
  margin: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.el-main {
  /*background-color: #E9EEF3;*/
  color: #333;
  height: 90% !important;
  margin: 2px 0px 2px 5px;
  border: #2b2f3a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  padding: 5px;
}

.el-container {
  height: 100%;
  background-color: #F3F3F4;
}
</style>
