import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import './permission'
import '@/icons'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import Viewer from 'v-viewer'
Vue.config.productionTip = false

Vue.use(ElementUI)

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
