// import {getToken, setToken, removeToken} from '@/utils/auth'
import {setToken, removeToken} from '@/utils/auth'
import request from '@/utils/request'
import axios from 'axios'

const state = {
    token: ''
}

const actions = {
    login({commit}, userInfo) {
        return new Promise((resolve) => {
            axios.post(
                'http://127.0.0.1:8091/oauth/token',
                `scope=app&username=${userInfo.username}&password=${userInfo.password}&grant_type=password`,
                {
                    headers: {
                        Authorization: 'Basic d2ViQXBwOjEyMzQ1Ng',
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            ).then((data) => {
                let token = data.data.access_token
                setToken(token)
                commit('SET_TOKEN', token)
                resolve()
            })
        })
    },
    logout({commit}) {
        return new Promise(resolve => {
            removeToken()
            commit('SET_TOKEN', '')
            resolve()
        })
    }
}

const mutations =
    {
        SET_TOKEN(state, token) {
            state.token = token
        }
    }

const getters = {
    token: state => state.token
}

export default {
    namespace: true,
    state,
    actions,
    mutations,
    getters
}
